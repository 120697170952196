.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700;800&display=swap');

*
{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    max-width: 100%;
}

.mt-15
{
    margin-top: 15vh !important;
}
.mb-15
{
    margin-bottom: 15vh !important;
}


.box-details h3
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #171F33;
}

.box-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #63666F;
}

.box
{
    height: auto;
    width: 100%;
    border: 1px solid #63666F33;
}

.box-icon
{
    position: relative;
    margin-top: -100px;
}

.box-icon img
{
    height: 120px;
    width: 120px;
}


.letsgo h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #171F33;
    margin-left: 25px;
}

.contactus h4
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #AC2027;
}

.service_header{
    margin: auto auto;
    width: 50%;
    text-align: center;

}

.service_header h1{
    font-family: 'Montserrat', sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: 120px;
  letter-spacing: 0em;
  color:#171F33;
    

}

.upper-left h1{
    position: absolute;
width: 408px;
height: 98px;


font-family: 'Montserrat';
font-style: normal;
font-weight: 800;
font-size: 80px;
line-height: 98px;
/* identical to box height */


color: #171F33;
}


.upper-right{
    padding-top: 30px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #63666E;
}


.team-deatils h4
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171F33;
}

.team-deatils h5
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #63666F;
    opacity: 0.6;
}

.our-work ul
{
    list-style: none;
    display: flex;
    flex-direction: row;
}

.our-work ul li a
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #63666F;
    opacity: 0.6;
    text-decoration: none;
    margin-right: 55px;
}

.our-work ul li.red a
{
    color: #AC2027 !important;
    opacity: 10;
}

.content
{
    position: absolute;
    top: 50%;
    opacity: 0;
    visibility: hidden;
}

#click:checked ~ .content
{
    opacity: 1;
    visibility: visible;
}

.message input
{
    width: 100%;
    height: 7vh;
    border: 1px solid rgba(99, 102, 111, 0.2);
    box-sizing: border-box;
    padding-left: 25px;
} 

.message textarea
{
    width: 100%;
    height: 132px;
    border: 1px solid rgba(99, 102, 111, 0.2);
    box-sizing: border-box;
    padding-left: 25px;
    padding-top: 15px;
}

.send
{
    float: right;
    padding: 16px 48px;
    width: 138px;
    height: 52px;
    background: #AC2027;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.place img
{
    width: 94px;
    height: 94px;
}

.place-details
{
    padding-left: 25px;
}

.place-details h5
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #171F33;
}

.place-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #63666F;
}

.contact-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #63666F;
    margin-left: 15px;
}

.contact-details i
{
    border: 1px solid rgba(99, 102, 111, 0.2);
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
