.gallery-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 135px;
}

.gallery-img{
    padding: 7px;
    height: 100%;
}

.img-80{
    width: 80%;
}

.img-60{
    width: 60%;
}

.img-50{
    width: 50%;
}

.img-40{
    width: 40%;
}

.img-33{
    width: 33.334%;
}

.img-25{
    width: 25%;
}

.img-25{
    width: 25%;
}

.img-h-50{
    height: 50%;
}

@media (max-width: 500px){
    .gallery-img{
        height: 100%;
        width: 100%;
    }
    .gallery-img-container{
        width: 100%;
        height: 100%;
    }
}