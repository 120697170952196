@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@400;500;700;800&display=swap');

*
{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    max-width: 100%;
}

body, html{
    overflow-x: hidden;
}

.mt-15
{
    margin-top: 60px !important;
}
.mb-15
{
    margin-bottom: 48px !important;
}

a:link,
a:visited
{
    text-decoration: none;
}

.active
{
    color: #FFFFFF;
    font-weight: 500;
}

.navbar
{
    padding-top: 15px;
    padding-bottom: 10px !important;
}

.menu-list li a:active{
    color: #ffffff !important;
}

.menu-list li a:focus{
    color: #ffffff !important;
}

.menu-list li a:hover{
    color: #ffffff !important;
}

header ul
{
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

header ul li
{
    float: left;
}

header ul li a
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: block;
    text-align: center;
    color: #6E7377;
    padding: 20px 15px;
}

header ul li a:nth-child(8)
{
    color: #ffffff;
}

.text-red{
    color: #AC2027;
}


.py-5 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}
.head h1
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 95px;
    color: #171F33;
}

.head h3
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #63666F;
}

.head-nxt button
{
    margin-top: 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #AC2027;
    padding: 15px 40px 15px 40px;
    margin-right: 50px;
}

.home1 h2,
.home6 h2,
.latest-post h2
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 68px;
    color: #171F33;
}
.latest-post
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-header{
    margin-top: 12vh;
    margin-bottom: 22vh;
}

.home1 p,
.home6 p,
.latest-post p,
.post-deatails p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #63666F;
}

.latest-post p
{
    width: 50%;
}
.home4{
    padding: 110px 80px;
}

.home4 p
{
    width: 100%;
    margin-top: 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #FFFFFF;
}
.home4 img{
    width: 85px;
    height: 85px;
}

.background-overflow{
    position: relative;
}

.background-overflow::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: #171F33;
}

.head-nxt 
{
    display: flex;
}

.footer{
    position: relative;
    z-index: 15;
}

.foot{
    padding-top: 5%;
}



.footer-details ul li a {
    list-style: none;
    color: rgba(255, 255, 255, 0.4);
    padding-left: 0;
    font-size: 17px;
}


.footer-heading h2
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 46px;
    color: #FFFFFF;
}

.footer-heading h3
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
    color: #FFFFFF;
}

.footer-details p
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.4);
}

.footer-icons
{
    color: #FFFFFF;
    background: #202B46;
    border-radius: 50%;
    padding: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}

.footer-details ul li
{
    list-style: none;
    color: rgba(255, 255, 255, 0.4);
    padding-left: 0;
}

.hamberger-menu
{
    display: none;
}

.hamberger-menu a{
    color: #fff;
}

.box-details h3
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #171F33;
}

.box-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #63666F;
}

.box
{
    height: auto;
    width: 100%;
    border: 1px solid #63666F33;
}



.letsgo{
    padding-bottom: 10%;
    padding-top: 10%;
}

.letsgo h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 39px;
    color: #171F33;
    margin-left: 25px;
}

.letsgo img{
    height: 80px;
    width: 80px;
}

.contact-us--form{
    width: 90%;
    float: right;
    padding-left: 10%;
}

.contactus h4
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #AC2027;
    padding: 15px 40px 15px 40px;
}

.service-header{
    margin: auto auto;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-header h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120px;
    letter-spacing: 0em;
    color:#171F33;
}

.service-header p
{
    width: 60%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #63666F;
}

.gallery_header h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 59px;

}

.team-deatils h4
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #171F33;
}

.team-deatils h5
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 8px;
    color: #63666F;
    opacity: 0.6;
}

/* .mt-15{
    margin-top: 40px !important;
} */

.order-2{
    margin: inherit;
}

.our-work ul
{
    list-style: none;
    display: flex;
    flex-direction: row;
}

.our-work ul li a
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #63666F;
    opacity: 0.6;
    text-decoration: none;
    margin-right: 55px;
    cursor: pointer;
}

.our-work ul li.red a
{
    color: #AC2027 !important;
    opacity: 10;
}

.icon
{
    height: 302px;
    border: 1px solid rgba(99, 102, 111, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal
{
    display: block !important;
    background: #63666E;
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
}

.modal__content
{
    width: 50%;
    height: auto;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in;
}

.modal__heading
{
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(99, 102, 111, 0.2);;
}

.modal__close
{
    color: #63666F;
    font-size: 2.5em;
    position: absolute;
    top: .5em;
    right: 1em;
}

.modal__paragraph
{
    padding-top: 25px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #63666F;
}

#modal:target
{
    opacity: 1;
    visibility: visible;
}

#modal:target .modal__content
{
    opacity: 1;
    visibility: visible;
}

.message-details
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.message input
{
    width: 100%;
    height: 56px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.6);
} 

.message textarea
{
    width: 100%;
    height: 132px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    box-sizing: border-box;
    padding-left: 25px;
    padding-top: 15px;
    color: rgba(255, 255, 255, 0.6);
}

.send
{
    float: right;
    padding: 16px 48px;
    width: 100%;
    height: 52px;
    background: #AC2027;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.place img
{
    width: 94px;
    height: 94px;
}

.place-details
{
    padding-left: 25px;
}

.place-details h5
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #171F33;
}

.place-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #63666F;
}

.contact-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #63666F;
    margin-left: 15px;
}

.contact-details i
{
    border: 1px solid rgba(99, 102, 111, 0.2);
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-box
{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 704px;
    height: 64px;
    border: 1px solid rgba(99, 102, 111, 0.4);
}

.search-txt input
{
    border: none;
    width: 40rem;
    height: 3.8rem;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(99, 102, 111, 0.6);
    padding-left: 25px;
}
textarea:focus, input:focus{
    outline: none;
}
.search-btn a i
{
    padding-right: 25px;
    color: rgba(99, 102, 111, 0.6);
}

.search-list ul
{
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.search-list ul li
{
    margin: 5px 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #63666F99;
}

.blog-date p
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    color: #63666F;
}

.blog-head h2,
.nxt-blog h2
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    color: #171F33;
}

.blog-details p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #63666F;
}

.categories ul
{
    list-style: none;
    padding-left: 0;
    padding-top: 20px;
}

.categories ul li
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: rgba(99, 102, 111, 0.6);
    padding: 5px 0px;
}

.post-img{
    width: 395px;
    height: 395px;
}

.post-img img{
    width: 100%;
    height: 100%;
}

.career{
    padding-top: 15px;
}

.career h2
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 49px;
    color: #171F33;
}

.career p
{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #63666F;
}

.upper-left h1{
    position: absolute;
    width: 408px;
    height: 98px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 72px;
    color: #171F33;
}

.upper-right p{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700 !important;
    line-height: 150%;
    color: #63666E;
}



.upper-right{
    font-weight: 400;
    font-size: 20px;
}

b
{
    color: #171F33;
}

.whats-app {
    position: fixed;
    background-color: green;
    width: 60px;
    height: 60px;
    bottom: 40px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 24px;
    z-index: 100;
    right: 15px;
}

.whats-app i
{
    background: none;
}

.my-float {
    margin-top: 16px;
}

.overlay{
    display: block;
    width: 50vw;
    height: 100vh;
    background-color: rgb(23, 31, 51);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
}

.gallery-img{
    width: 100%;
    display: block;
}

.homeSlider{
    height: 100%;
}
.homeSlider .swiper-pagination{
    bottom: 30px;
    text-align: right;
}

.homeSlider .swiper-pagination .swiper-pagination-bullet{
    background: rgba(249, 250, 253, 0.1);
}

.homeSlider .swiper-pagination .swiper-pagination-bullet-active{
    background: rgba(172, 32, 39, 1);
}

footer{
    position: relative;
    font-family: 'Montserrat';;
    z-index: 15;
}

.menu1
{
    display: none;
} 

.our-team-list
{
    margin-top: 35px;
    margin-bottom: 150px;
}

@media (max-width: 500px){
    .letsgo h2 
    {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .row{
        margin-left: 0px ;
    }

    .mt-15{
        margin-top: 70px !important;
    }
    .text-sm-center{
        text-align: center !important;
    }
    .overlay{
        width: 100vw;
        height: 650px;
        background-color: rgb(23, 31, 51);
        position: absolute;
        top: 80px;
        right: 0;
        z-index: 10;
    }
    .menu, .menu1
    {
        display: none;
    }

    .active
    {
        color: #101010;
    }

    .hamberger-menu
    {
        display: block;
        color: #101010;
        font-size: 24px;
        float: right;
    }

    .menu-mobile-view
    {
        top: 72px;
        left: 0;
        display: block;
        position: fixed;
        padding: 50px 0px;
        width: 100vw;
        height: 100vh;
        background: #ffffff;
        transition: 0.5s;
        z-index: 999;
        padding-left: 15px;
    }

    .menu-mobile-view1
    {
        top: 72px;
        left: 0;
        display: block;
        position: fixed;
        padding: 50px 0px;
        width: 100vw;
        height: 100vh;
        background: #ffffff;
        transition: 0.5s;
        z-index: 999;
        padding-left: 15px;
    }

    header ul li
    {
        float: none;
    }

    header ul li a
    {
        text-align: left;
        font-size: 25px;
        line-height: 25px;
    }

    .head h1
    {
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }

    /* .section-heading{
        text-align: center;
    
    } */

    .head h3
    {
        font-size: 15px;
    }

    /* .home4
    {
        padding: 0 !important;
    } */
    .head-nxt
    {
        display: flex;
        text-align: center;
        justify-content: space-around;
    }

    .head-nxt button{
        margin: 0px;
        padding: 16px 2px;
        width: 45%;
    }
    .home-header{
        margin-top: 35px;
    }
    /* .head-img,
    .home2 p, .home3 p, .home6 p, .latest-post p, .post-deatails p
    {
        padding: 1rem !important;
    } */

    .home2 p, .home3 p, .home6 p, .service-header p, .latest-post p, .post-deatails p
    {
        font-size: 15px;
    }
    
    .home1 h2, .home6 h2, .latest-post h2
    {
        font-size: 27px;
        line-height: 39px;
    }


    .home4
    {
        padding: 2rem;
    }
    .home4 p
    {
        width: 100%;
        font-size: 19px;
    line-height: 29px;
    }

    .home1
    {
        padding: 0px 0px 100px 0px !important;
    }

    .home5, .home2, .home3
    {
        padding: 0rem !important;
    }

    .service-header
    {
        align-items: flex-start;
        text-align: left;
    }

    .service-header p
    {
        width: 100%;
    }

    .letsgo
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 25px;
    }

    .letsgo h2
    {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .box
    {
        margin-bottom: 60px;
        height: 50vh;
    }

    .service-header h1
    {
        font-size: 32px;
        line-height: 45px;
        align-items: start;
    }

    .box-details p,
    .box-details h3,
    .upper-right p
    {
        font-size: 15px;
        line-height: 150%;
    }

    .latest-post p
    {
        width: 100%;
    }
    
    .upper-left h1
    {
        font-size: 25px;
        line-height: 39px;
    }

    .message-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0 !important;
    }

    .message-details1 input
    {
        width: 100% !important;
    }

    .message input
    {
        margin-top: 20px;
        width: 100%;
    }

    .search-txt input
    {
        width: 19rem;
    }

    .mb-15{
        margin-bottom: 35px !important;
    }
    .mt-15{
        margin-top: 35px !important;
    }

    .background-overflow::after{
        width: 105vw;
        left: -15px;        
    }
    .our-work ul{
        overflow-x: scroll;
        padding: 0px;
        -ms-overflow-style: none;
        scrollbar-width: none
    }
    .our-work ul::-webkit-scrollbar{
        display: none;
        width: 0;
        height: 0;
    }
    .our-work ul li a{
        margin-right: 30px;
    }

    .modal__content{
        width: 100%;
    }

    .our-work-icons .col-sm-12{
        padding: 0px;
    }

    .our-work-icons .col-sm-12:not(:last-child){
        margin-bottom: 30px;
    }

    .contact-us--form
    {
        float: none;
        width: 100%;
        padding-left: 0;
    }

    .message-text
    {
        margin-top: -20px ;
    }
}